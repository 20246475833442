export default {
  beforeRouteLeave(to, from, next) {
    if (this.formWasModified) {
      if (this.userAgreedToLeave) {
        next()
      } else {
        this.open = true
        this.nextRoute = to
      }
    } else {
      next()
    }
  },
  data() {
    return {
      open: false,
      nextRoute: null,
      userAgreedToLeave: false,
    }
  },
  computed: {
    formWasModified: ({ $v, $refs }) => $v.$anyDirty || $refs.subForm?.$v?.$anyDirty,
  },
  methods: {
    navigate() {
      this.userAgreedToLeave = true
      this.open = false
      this.$router.push({ path: this.nextRoute.path })
    },
  },
}
