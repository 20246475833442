<template>
  <div v-if="isDataLoaded">
    <!-- Controllers -->
    <div color="white" class="d-flex justify-end py-4 pr-6">
      <v-btn color="primary" depressed type="submit" dark @click.prevent="update()" v-text="$t('common.publish')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 pt-0 pb-6 bigScreenForm">
      <v-row>
        <v-col md="8" sm="12">
          <v-row style="height: 200px;">
            <v-col class="d-flex flex-column justify-space-around">
              <h3 class="mb-4" v-text="$t('common.info')" />
              <v-text-field
                v-if="isDataLoaded"
                v-model="form.name"
                :error-messages="getFieldErrors('name')"
                :label="$t('common.name')"
                class="my-2"
                outlined
                dense
                hide-details="auto"
                @blur="$v.form.name.$touch()"
              />
              <v-select
                v-if="isDataLoaded"
                v-model="form.sport"
                :error-messages="getFieldErrors('sport')"
                :label="$t('common.sport')"
                class="my-2"
                :items="sports"
                style="align-items: flex-end !important;"
                outlined
                dense
                hide-details="auto"
                @blur="$v.form.sport.$touch()"
              />
            </v-col>
          </v-row>
          <v-row style="height: auto;">
            <v-col class="d-flex justify-center align-center">
              <v-row>
                <html-field
                  v-model="form.detailDescription"
                  :placeholder="$t('project.editProject.detailDescription')"
                  :error-messages="getFieldErrors('detailDescription')"
                  class="ma-3"
                  @blur="$v.form.detailDescription.$touch()"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-row style="height: 200px;">
            <v-col>
              <v-row>
                <v-col cols="6">
                  <div class="mb-4 d-flex">
                    <h3 class="mr-3" v-text="$t('common.logo')" />
                    <global-tooltip
                      :text="$t('common.tooltipImageSizes', {
                        proportion: '1:1',
                        width: '200px',
                        height: '200px'
                      })"
                    />
                  </div>
                  <image-field
                    v-if="isDataLoaded"
                    v-model="form.logo"
                    :error-messages="getFieldErrors('logo')"
                    :width="200" :height="200"
                    suffix="_logo"
                  />
                </v-col>
                <v-col cols="6">
                  <div class="mb-4 d-flex">
                    <h3 class="mr-3" v-text="$t('project.editProject.banner')" />
                    <global-tooltip
                      :text="$t('common.tooltipImageSizes', {
                        proportion: '16:9',
                        width: '640px',
                        height: '360px'
                      })"
                    />
                  </div>
                  <image-field
                    v-model="form.banner"
                    :error-messages="getFieldErrors('banner')"
                    :width="640" :height="360"
                    suffix="_banner"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-4 d-flex">
                <h3 class="mr-3" v-text="`${$t('project.editProject.video')} ${$t('common.optional')}`" />
                <global-tooltip
                  :text="$t('project.editProject.tooltipVideoYoutube')"
                />
              </div>
              <video-field
                v-model="form.video"
                :error-messages="getFieldErrors('video')"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import formUtils from '@/utils/mixins/formUtils'
import localeUtils from '@/utils/mixins/localeUtils'
import saveDialogUtils from '@/utils/mixins/saveDialog'

export default {
  name: 'OrganizationSettings',
  components: {
    ImageField: () => import('@/components/formFields/ImageField'),
    HtmlField: () => import('@/components/formFields/HtmlField'),
    TermsList: () => import('@/components/terms/TermsList'),
    VideoField: () => import('@/components/formFields/VideoField'),
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog.vue'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils, localeUtils, saveDialogUtils],
  props: {
    organizationId: { type: String, required: true },
  },
  data() {
    return {
      sportsAvailable: ['soccer', 'basketball', 'handball', 'guild'],
      form: {},
      isDataLoaded: false,
    }
  },
  validations() {
    return {
      form: {
        logo: { required },
        banner: { required },
        video: {},
        name: { required },
        sport: { required },
        detailDescription: { required, maxLength: maxLength(73) },
        terms: { required },
      },
    }
  },
  computed: {
    ...mapGetters({ organizationData: 'organization/data' }),
    sports() {
      return this.sportsAvailable.map(sport => ({ text: this.$t(`sports.${sport}`), value: sport }))
    },
  },
  mounted() {
    const { logo, name, sport } = this.organizationData
    this.form = {
      logo,
      banner: this.organizationData?.banner ?? null,
      video: this.organizationData?.video ?? null,
      name,
      sport,
      detailDescription: this.organizationData?.detailDescription ?? '',
    }
    this.isDataLoaded = true
  },
  methods: {
    async update() {
      let response
      const { organizationId, form } = this
      this.runAsync(() => {
        response = this.$store.dispatch('organization/update',
          {
            organizationId,
            data: form,
          })
      })
      if (response) this.$store.dispatch('alert/openAlertBox', ['alertSuccess', 'Success'])
      this.userAgreedToLeave = true
    },
  },
}
</script>

<style lang="scss">
::v-deep .tiptap-vuetify-editor__content {
  max-height: 150px;
}

.v-input__slot {
  margin-bottom: 0 !important;
}

.v-messages.theme--light {
  display: none;
}

.image-input {
  max-width: 100% !important;
  min-height: 100px !important;
}
</style>
